<template>
  <v-container>
    <v-layout align-center justify-center>
      <v-card class="mx-auto" elevation="5" tile flat width="100%" max-width="500px" style="">
        <v-toolbar color="primary" dark>
          <v-btn icon to="/login" exact>
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-container>
            <v-row class="justify-center align-center">
              <img :src="logo" style="max-height: 60px">
            </v-row>
          </v-container>
        </v-toolbar>

        <v-container>
          <v-alert
            text
            align="left"
            border="left"
            type="info"
          >
            <p>Fill in your e-mail address provided during the registration. You will receive instructions how to reset your password.</p>

            <p><strong>If you didn't receive the password recovery e-mail in few minutes, please, check your SPAM folder.</strong></p>

            <small>In case of any problems, write us an e-mail to <a href="mailto: admin@mk.cvut.cz">admin@mk.cvut.cz</a>.</small>
          </v-alert>
        </v-container>

        <v-card-text align="center">
          <v-form @submit.prevent="onSubmit" v-model="isValid">
            <v-text-field v-model="email" :rules="[rules.required, rules.valid_email]" label="E-mail" type="email"></v-text-field>
            <v-btn class="ma-2" color="primary" type="submit" :disabled="!isValid">Send</v-btn>
          </v-form>
        </v-card-text>
      </v-card>

    </v-layout>
  </v-container>

</template>

<script>
import logo from '@/assets/mk-logo-white.svg'

export default {
  data () {
    return {
      logo,
      email: '',
      isValid: false,
      rules: {
        required: value => !!value || 'Required field',
        valid_email: v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
      }
    }
  },
  methods: {
    onSubmit () {
      this.$store.dispatch('auth/forgotPassword', this.email)
    }
  },
  async mounted () {
    document.title = 'Forgotten password | Masarka Student Club'
  }
}
</script>
